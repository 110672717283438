import "./style.css";

const FIRST_ACTION_TIME = 800,
  PAUSE_TIME = 3000,
  TOTAL_ACTION_TIME = 3600;

const lines = document.querySelector(".lines");
const svg = document.querySelector(".animation-svg");

setTimeout(() => {
  lines.style.animationPlayState = "paused";
}, FIRST_ACTION_TIME);
setTimeout(() => {
  lines.style.animationPlayState = "running";
}, PAUSE_TIME);

setInterval(() => {
  setTimeout(() => {
    lines.style.animationPlayState = "paused";
  }, FIRST_ACTION_TIME);
  setTimeout(() => {
    lines.style.animationPlayState = "running";
  }, PAUSE_TIME);
}, TOTAL_ACTION_TIME);

const setSize = () => {
  const width = window.innerWidth;
  console.log("width", width);
  console.log("first");
  if (width < 480) {
    svg.setAttribute("viewBox", "0 0 1000 500");
  } else {
    svg.setAttribute("viewBox", "0 0 1000 772");
  }
};
setSize();

window.addEventListener("resize", setSize);
